var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checkout-cart-body" },
    [
      !_vm.loading
        ? _c(
            "div",
            [
              _c(
                "b-card",
                { staticClass: "p-0", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-1",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _vm._t("cart-header"),
                          _c("div", [
                            _vm.cartEmpty
                              ? _c("em", [_vm._v("Your cart is empty.")])
                              : _c(
                                  "div",
                                  { staticClass: "cart-items" },
                                  [
                                    _c("cart-item-list", {
                                      attrs: { dashed: true },
                                    }),
                                  ],
                                  1
                                ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.total
                ? _c(
                    "b-card",
                    { staticClass: "cart-total", attrs: { "no-body": "" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-between pr-3 pl-3",
                        },
                        [
                          _c("span", [_vm._v("Payment")]),
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm._f("dollars")(_vm.cartItemsTotal))
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("select-payment-method", {
                    attrs: {
                      "is-billing-active": _vm.isBillingActive,
                      "card-header-text": _vm.selectPaymentMethodCardHeaderText,
                      total: _vm.cartItemsTotal,
                    },
                    on: {
                      missingPaymentMethod: _vm.missingPaymentMethod,
                      closeSection: _vm.closeSection,
                      togglePanel: _vm.togglePanel,
                    },
                  }),
                ],
                1
              ),
              _vm.showSubmitRequest
                ? _c("checkout-cart-submit-payment", {
                    attrs: {
                      "verify-order-checkout": _vm.verifyOrderCheckout,
                      "alternate-checkout-button-label":
                        _vm.alternateCheckoutButtonLabel,
                    },
                    on: {
                      "submit-vto": function ($event) {
                        return _vm.$emit("checkout-completed")
                      },
                      "verify-and-checkout": _vm.verifyAccountInfoAndCheckout,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "text-center p-3" },
            [
              _c("h4", [_vm._v("\n      Processing Order\n    ")]),
              _c("ct-centered-spinner"),
            ],
            1
          ),
      _c("collect-account-info", {
        ref: `collectAccountInfoModal-${_vm.uid}`,
        on: { success: _vm.completeCheckout },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }